/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'reactstrap';
import { FaInfoCircle } from 'react-icons/fa';
import NProgress from 'nprogress';
import axios from 'axios';
import Moment from 'react-moment';
import { trialDetailsUrl } from '../constants/config';
import Layout from '../components/layout';
import Map from '../components/map/map';
import { sortLocations } from '../components/map/map-utils';
import ModalCriteria from '../components/criteria/modal-criteria';
import { useNavigation } from '../helpers/navigate';
import {
  Container,
  Fonts,
  DetailsPageContainer,
  StyledTooltip,
  StudyNotFoundContainer,
} from '../global.css';
import { trackAnalytics } from '../helpers/trackAnalytics';

const DetailsPage = ({ data, pageContext }) => {
  const [studyData, setStudyData] = useState(null);
  const [hasIpd, setHasIpd] = useState(null);
  const [activeLocations, setActiveLocations] = useState(null);
  const searchParams = useRef();
  const navigate = useNavigation(`/${pageContext.langKey}/studies/`);
  const [apiBadResponse, setApiBadResponse] = useState(false);

  useEffect(() => {
    NProgress.start();
    loadData();
  }, []);

  const loadData = () => {
    let elementId = null;
    try {
      searchParams.current = new URL(location.href).searchParams;
      searchParams.current.searchLat = searchParams.current.get('searchLat');
      searchParams.current.searchLong = searchParams.current.get('searchLong');
      searchParams.current.searchRadius = searchParams.current.get(
        'searchRadius'
      );
      searchParams.current.searchName = searchParams.current.get('name');
      searchParams.current.baseLat = searchParams.current.get('baseLat');
      searchParams.current.baseLong = searchParams.current.get('baseLong');
      searchParams.current.oldId = searchParams.current.get('oldId');
      elementId = searchParams.current.get('id');
    } catch (e) {
      elementId = location.search.substr(4);
    }

    if (!elementId || elementId.length === 0) {
      NProgress.done();
      return navigate.go();
    }

    axios
      .get(
        trialDetailsUrl(
          encodeURIComponent(elementId),
          searchParams.current.oldId ? 'old' : ''
        )
      )
      .then(res => {
        NProgress.done();
        setStudyData(res.data.protocol_form);
        const ipd = res.data.protocol_form.fields.find(
          val => val.name.toLowerCase() === 'patient level data'
        );
        if (ipd.value.toLowerCase() === 'yes') {
          setHasIpd(true);
        }
        trackAnalytics('TrialDetailsResponse', {
          TrialId: elementId,
          searchLat: searchParams.current.searchLat,
          searchLong: searchParams.current.searchLong,
          searchRadius: searchParams.current.searchRadius,
          baseLat: searchParams.current.baseLat,
          baseLong: searchParams.current.baseLong,
          Success: true,
        });
      })
      .catch(error => {
        trackAnalytics('TrialDetailsResponseFailure', {
          Error: error.message,
          TrialId: elementId,
          searchLat: searchParams.current.searchLat,
          searchLong: searchParams.current.searchLong,
          searchRadius: searchParams.current.searchRadius,
          baseLat: searchParams.current.baseLat,
          baseLong: searchParams.current.baseLong,
        });
        NProgress.done();
        setApiBadResponse(true);
      });
  };

  const setActiveLoc = locations => {
    setActiveLocations(locations);
  };

  const renderLocationsTable = () => {
    let locations = null;
    if (activeLocations !== null) {
      locations = sortLocations(
        activeLocations,
        searchParams.current.searchLat,
        searchParams.current.searchLong,
        'distance',
        searchParams.current.searchName
      );
    } else {
      locations = studyData.locations;
    }
    return (
      <table>
        <tbody>
          {locations.map((loc, index) => (
            <tr key={index}>
              <td>
                {`${
                  loc.CountryDisplay ? `${loc.CountryDisplay}` : 'Not Available'
                },  ${
                  loc.StateDisplay ? `, ${loc.StateDisplay}` : 'Not Available'
                }${loc.City ? `, ${loc.City}` : 'Not Available'}${
                  loc.PostalCode ? `, ${loc.PostalCode}` : 'Not Available'
                }`}
              </td>
              <td>{studyData.display_status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const trackLink = (event, attachment) => {
    trackAnalytics('DocumentDownload', {
      attachment: attachment.TypeInternal,
      TrialId: studyData.org_study_id,
      TrialTitle: studyData.brief_title,
      TrialStatus: studyData.display_status_internal,
      Conditions: studyData.conditions.map(cond => cond.InternalValue),
    });

    if (event.target && typeof window !== 'undefined' && window.ga) {
      try {
        window.ga.getAll().forEach(_ga => {
          _ga.send('event', 'Downloads', 'Downloads', event.target.href);
        });
      } catch (ev) {
        console.log(ev);
      }
    }
  };
  const renderStudyNotFound = () => {
    return (
      <Fonts>
        <StudyNotFoundContainer>
          <h1 className={'main-title'}>{data.studyNotFoundJson.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.studyNotFoundJson.content.childMarkdownRemark.html,
            }}
          />
        </StudyNotFoundContainer>
      </Fonts>
    );
  };

  const isClinicalSynopsisAvailable = attachments =>
    attachments.some(doc => doc.TypeInternal === 'ATTACHTYPE_STUDY_SYNOPSIS');

  const EUCTNumber =
    studyData?.secondary_ids &&
    studyData.secondary_ids.find(ids => ids.id_type === "Registry Identifier" && ids.id_domain === 'CTIS');

  return (
    <Layout
      meta={{
        pageTitle: '| ViiV',
        pageDescription: `${
          !studyData
            ? null
            : studyData.official_title.length > 0
            ? studyData.official_title
            : studyData.brief_summary
        }`,
      }}
    >
      <Container>
        {apiBadResponse ? (
          renderStudyNotFound()
        ) : (
          <Fonts>
            {!studyData ? null : (
              <DetailsPageContainer>
                <button
                  className="back-to-search"
                  onClick={event => {
                    event.preventDefault();
                    window.history.go(-1);
                  }}
                >
                  {data.trialDetailsJson.returnSearch}
                </button>
                <Row className="hero-section">
                  <Col lg={{ size: 10, order: 1 }} xs={{ size: 12, order: 2 }}>
                    <strong>{`${data.trialDetailsJson.description}:`}</strong>
                    <p>{studyData.brief_summary}</p>
                  </Col>
                  <Col
                    lg={{ size: 2, order: 2 }}
                    xs={{ size: 12, order: 1 }}
                    className={'status-container'}
                  >
                    <div className="status-card">
                      <div
                        className={
                          'status-icon ' +
                          `${studyData.display_status}`
                            .replace(',', '')
                            .replace(/\s/g, '_')
                            .toLocaleLowerCase()
                        }
                      />
                      <strong>
                        {studyData.study_type.includes('Expanded Access')
                          ? studyData.overall_status
                          : studyData.display_status_internal ===
                            'RECSTATUS_RELIQUISHED'
                          ? 'No Longer a ViiV Study'
                          : studyData.display_status}
                      </strong>
                    </div>
                  </Col>
                  <Col xs={{ size: 12, order: 3 }} className={'study-info-col'}>
                    <strong>{`${data.trialDetailsJson.identifier}: `}</strong>
                    <FaInfoCircle id="study_id" />
                    <p>
                      {studyData.org_study_id
                        ? studyData.org_study_id
                        : 'Not available'}
                    </p>
                    {studyData.attachments &&
                    studyData.attachments.length > 0 ? (
                      <div className="has-results">
                        <div>
                          <img
                            src="/icons/study-has-result.png"
                            alt="Study Has Results Documents"
                          />
                        </div>
                        <a href="#documents-section">
                        <span>{data.trialDetailsJson.docAvailable}</span>
                        </a>
                      </div>
                    ) : null}
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="study_id"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.trialDetailsJson.popoversText.studyId
                              .childMarkdownRemark.html,
                        }}
                      />
                    </StyledTooltip>
                  </Col>
                  <Col xs={{ size: 12, order: 4 }} className={'study-info-col'}>
                    <strong>{`${data.trialDetailsJson.clinicalID}: `}</strong>
                    <FaInfoCircle id="ct_gov_id" />
                    <p>
                      {studyData.nct_number ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://clinicaltrials.gov/study/${studyData.nct_number}?rank=1`}
                        >
                          {studyData.nct_number}
                        </a>
                      ) : (
                        'Not Applicable'
                      )}
                    </p>
                    {studyData.HasResults === 'true' &&
                    studyData.IsReleasedToCtGov === 'true' ? (
                      <div className="has-results">
                        <div>
                          <img
                            src="/icons/study-has-result.png"
                            alt="Study Has Results"
                          />
                        </div>
                        <a href="#documents-section">
                        <span>{data.trialDetailsJson.resultsAvailable}</span>
                        </a>
                      </div>
                    ) : null}
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="ct_gov_id"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.trialDetailsJson.popoversText.ctGov
                              .childMarkdownRemark.html,
                        }}
                      />
                    </StyledTooltip>
                  </Col>
                  <Col xs={{ size: 12, order: 5 }} className={'study-info-col'}>
                    <strong>{`${data.trialDetailsJson.EudraCtID}: `}</strong>
                    <FaInfoCircle id="eudra_id" />
                    <p>
                      {studyData.secondary_ids &&
                      studyData.secondary_ids.find(
                        ids => ids.id_type === 'EudraCT Number'
                      ) ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://www.clinicaltrialsregister.eu/ctr-search/search?query=${
                            studyData.secondary_ids.find(
                              ids => ids.id_type === 'EudraCT Number'
                            ).id
                          }`}
                          title={
                            studyData.secondary_ids.find(
                              ids => ids.id_type === 'EudraCT Number'
                            ).id
                          }
                        >
                          {
                            studyData.secondary_ids.find(
                              ids => ids.id_type === 'EudraCT Number'
                            ).id
                          }
                        </a>
                      ) : (
                        'Not Applicable'
                      )}
                    </p>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="eudra_id"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.trialDetailsJson.popoversText.eudraCT
                              .childMarkdownRemark.html,
                        }}
                      />
                    </StyledTooltip>
                  </Col>
                  <Col xs={{ size: 12, order: 6 }} className={'study-info-col'}>
                    <strong>{`${data.trialDetailsJson.euCtID}: `}</strong>
                    <FaInfoCircle id="eu_ct_id" />
                    <p>
                      {EUCTNumber ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://euclinicaltrials.eu/search-for-clinical-trials/?lang=en&EUCT=${EUCTNumber.id}`}
                          title={EUCTNumber.id}
                        >
                          {EUCTNumber.id}
                        </a>
                      ) : (
                        'Not Applicable'
                      )}
                    </p>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="eu_ct_id"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.trialDetailsJson.popoversText.euCt
                              .childMarkdownRemark.html,
                        }}
                      />
                    </StyledTooltip>
                  </Col>
                  {hasIpd && (
                    <Col
                      xs={{ size: 12, order: 7 }}
                      className="ipd-wrapper study-info-col"
                    >
                      <a
                        className={'patient-link'}
                        key={studyData.org_study_id}
                        href={`https://search.vivli.org/?search=${studyData.org_study_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Patient Level Data Available
                      </a>
                    </Col>
                  )}
                </Row>
                <hr />
                <h3>{data.trialDetailsJson.studyOverview}</h3>
                <Row className="overview-row">
                  <Col md={4} xs={12}>
                    <div className="overview-icon conditions" />
                    <h6>
                      {data.trialDetailsJson.overviewSection.conditionsDisplay}
                    </h6>
                    <p>
                      {studyData.conditions && studyData.conditions.length
                        ? studyData.conditions
                            .map(condition => condition.DisplayValue)
                            .filter(
                              (val, index, arr) => arr.indexOf(val) === index
                            )
                            .join(', ')
                        : 'N/A'}
                    </p>
                  </Col>
                  <Col md={4} xs={12} className="middle-column">
                    <div className="overview-icon product" />
                    <h6>{data.trialDetailsJson.overviewSection.product}</h6>
                    <p>
                      {studyData.product_name && studyData.product_name.length
                        ? studyData.product_name
                        : 'N/A'}
                    </p>
                  </Col>
                  <Col md={4} xs={12}>
                    <div className="overview-icon collaborators" />
                    <h6>
                      {data.trialDetailsJson.overviewSection.collaborators}
                    </h6>
                    <p>
                      {studyData.sponsors.collaborators &&
                      studyData.sponsors.collaborators.length
                        ? studyData.sponsors.collaborators
                            .filter(
                              (val, index, arr) => arr.indexOf(val) === index
                            )
                            .join(', ')
                        : 'N/A'}
                    </p>
                  </Col>
                </Row>
                <hr className={'none'} />
                <Row className="overview-row">
                  <Col md={4} xs={12}>
                    <div className="overview-icon date" />
                    <h6>{data.trialDetailsJson.overviewSection.dates}</h6>
                    {studyData.start_date ? (
                      <p className={'dates '}>
                        <Moment format="MMMM YYYY ">
                          {studyData.start_date}
                        </Moment>
                        {' To '}
                        {studyData.last_follow_up_date ? (
                          <Moment format=" MMMM YYYY ">
                            {studyData.last_follow_up_date}
                          </Moment>
                        ) : (
                          'N/A'
                        )}
                      </p>
                    ) : (
                      <p>{'N/A'}</p>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="middle-column">
                    <div className="overview-icon type" />
                    <h6>{data.trialDetailsJson.overviewSection.studyType}</h6>
                    <p>
                      {studyData.study_type && studyData.study_type
                        ? studyData.study_type
                        : 'N/A'}
                    </p>
                  </Col>
                  <Col md={4} xs={12}>
                    <div className="overview-icon phase" />
                    <h6>{data.trialDetailsJson.overviewSection.phase}</h6>
                    <p>
                      {studyData.display_phase
                        ? studyData.display_phase
                        : 'N/A'}
                    </p>
                  </Col>
                </Row>
                <hr className={'none'} />
                <Row className="overview-row">
                  <Col md={4} xs={12}>
                    <div className="overview-icon gender" />
                    <h6>{data.trialDetailsJson.overviewSection.gender}</h6>
                    <p>
                      {['All', 'Both'].indexOf(studyData.gender) >= 0
                        ? 'All'
                        : studyData.display_gender}
                    </p>
                  </Col>
                  <Col md={4} xs={12} className="middle-column">
                    <div className="overview-icon age" />
                    <h6>{data.trialDetailsJson.overviewSection.age}</h6>
                    <p>
                      {studyData.minimum_age_raw &&
                      studyData.maximum_age_raw &&
                      studyData.minimum_age_raw !== 'N/A' &&
                      studyData.maximum_age_raw !== 'N/A'
                        ? `${studyData.minimum_age_raw} - ${studyData.maximum_age_raw}`
                        : studyData.minimum_age_raw &&
                          studyData.minimum_age_raw !== 'N/A'
                        ? `${studyData.minimum_age_raw} + `
                        : studyData.maximum_age_raw &&
                          !studyData.maximum_age_raw.includes('N/A')
                        ? `N/A - ${studyData.maximum_age_raw}`
                        : 'N/A'}
                    </p>
                  </Col>
                  <Col md={4} xs={12}>
                    <div className="overview-icon healthyVol" />
                    <h6>
                      {data.trialDetailsJson.overviewSection.acceptVolunteers}
                    </h6>
                    <p>{studyData.healthy_volunteers || 'N/A'}</p>
                  </Col>
                </Row>
                <hr className={'none'} />
                <h3>{data.trialDetailsJson.studyDocuments}</h3>
                <Row className="documents-section">
                  <div className='documents-section-anchor' id="documents-section"></div>
                  {studyData.attachments &&
                  isClinicalSynopsisAvailable(studyData.attachments) ? (
                    <p className="documents-additional-text">
                      {data.trialDetailsJson.studyDocumentsAdditionalText}{' '}
                      <a
                        href={`mailto: ${data.trialDetailsJson.studyDocumentsAdditionalEmail}`}
                        rel="noopener noreferrer"
                      >
                        {data.trialDetailsJson.studyDocumentsAdditionalEmail}
                      </a>
                      <span>.</span>
                    </p>
                  ) : null}
                  {studyData.attachments && studyData.attachments.length > 0 ? (
                    studyData.attachments.map((doc, index) => (
                      <Col lg={4} md={6} key={index}>
                        <a
                          // Some chars in the attachment Url are already encoded, but we need all special chars encoded for IE11 support
                          // So we need to decode it before the encoding to be sure it will not break
                          href={encodeURI(decodeURI(doc.Url))}
                          alt="Download Document"
                          className="download-btn"
                          onClick={ev => trackLink(ev, doc)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {doc.TypeDisplay +
                            (doc.Language ? ` (${doc.Language})` : '')}
                          <span>
                            <img
                              src="/icons/icon-pdf.png"
                              alt="Download PDF Icon"
                            />
                          </span>
                        </a>
                      </Col>
                    ))
                  ) : (
                    <Col md={12}>
                      <p>{data.trialDetailsJson.noDocuments}</p>
                    </Col>
                  )}
                  {studyData.HasResults === 'true' &&
                  studyData.IsReleasedToCtGov === 'true' ? (
                    <Col md={12} className="learn-more">
                      <p>
                        {`${data.trialDetailsJson.isReleased.firstPart} `}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://clinicaltrials.gov/study/${studyData.nct_number}?rank=1`}
                        >
                          {data.trialDetailsJson.isReleased.linkText}
                        </a>
                        {` ${data.trialDetailsJson.isReleased.secondPart}`}
                      </p>
                    </Col>
                  ) : null}
                </Row>
                <hr />
                <h3>{data.trialDetailsJson.mapLocations}</h3>
                <Row className="locations-section">
                  <div className={'central-contact-container'}>
                    <Col md={4} xs={12}>
                      <strong>{`${data.trialDetailsJson.centralContact}: `}</strong>
                      <p>
                        {studyData.overall_contact.first_name &&
                        studyData.overall_contact.last_name
                          ? `${studyData.overall_contact.first_name} ${studyData.overall_contact.last_name}`
                          : 'Not Available'}
                      </p>
                    </Col>
                    <Col md={4} xs={12}>
                      <strong>{`${data.trialDetailsJson.centralPhone}: `}</strong>
                      <p>
                        {studyData.overall_contact.phone
                          ? studyData.overall_contact.phone
                          : 'Not Available'}
                      </p>
                    </Col>
                    <Col md={4} xs={12}>
                      <strong>{`${data.trialDetailsJson.centralEmail}: `}</strong>
                      {studyData.overall_contact.email ? (
                        <a
                          style={{ wordBreak: 'break-word' }}
                          href={`mailto:${studyData.overall_contact.email}`}
                          alt="Contact Email"
                        >
                          {studyData.overall_contact.email}
                        </a>
                      ) : (
                        <p>{'Not Available'}</p>
                      )}
                    </Col>
                  </div>
                  <Col xs={12} lg={8} className="map-container">
                    <Map
                      data={data.trialDetailsJson.mapSection}
                      studyLocations={studyData.locations.map(row => ({
                        ...row,
                        StudyId: studyData.org_study_id,
                        conditions:
                          studyData.conditions && studyData.conditions.length
                            ? studyData.conditions
                                .map(condition => condition.DisplayValue)
                                .filter(
                                  (val, index, arr) =>
                                    arr.indexOf(val) === index
                                )
                                .join(', ')
                            : 'N/A',
                      }))}
                      setActiveLoc={setActiveLoc}
                      isDynamic={true}
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    {studyData.locations && studyData.locations.length > 0 ? (
                      <div className="locations-table">
                        {renderLocationsTable()}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <hr />
                <h3>{data.trialDetailsJson.studyDesign}</h3>
                <Row>
                  <Col xs={12} lg={6} className={'study-design-up'}>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.purpose}: `}</strong>
                      {studyData.interventional_subtype
                        ? studyData.interventional_subtype
                        : 'Not Available'}
                    </p>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.allocation}: `}</strong>
                      {studyData.allocation
                        ? studyData.allocation
                        : 'Not Available'}
                    </p>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.study_design}: `}</strong>
                      {studyData.assignment
                        ? studyData.assignment
                        : 'Not Available'}
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className={'study-design-up'}>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.masking}: `}</strong>
                      {studyData.MaskingInfo.no_masking.includes('true') ||
                      studyData.masking.includes('Open Label')
                        ? 'None (Open Label)'
                        : 'Not Available'}
                    </p>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.masked_subject}: `}</strong>
                      {studyData.MaskingInfo.masked_subject &&
                      studyData.MaskingInfo.masked_subject.includes('true')
                        ? 'True'
                        : 'Not Available'}
                    </p>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.masked_caregiver}: `}</strong>
                      {studyData.MaskingInfo.masked_caregiver &&
                      studyData.MaskingInfo.masked_caregiver.includes('true')
                        ? 'True'
                        : 'Not Available'}
                    </p>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.masked_investigator}: `}</strong>
                      {studyData.MaskingInfo.masked_investigator &&
                      studyData.MaskingInfo.masked_investigator.includes('true')
                        ? 'True'
                        : 'Not Available'}
                    </p>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.masked_assessor}: `}</strong>
                      {studyData.MaskingInfo.masked_assessor &&
                      studyData.MaskingInfo.masked_assessor.includes('true')
                        ? 'True'
                        : 'Not Available'}
                    </p>
                  </Col>
                  <Col xs={12} className={'outcomes-section left-pad'}>
                    <strong>{`${data.trialDetailsJson.designSection.primary_outcomes}: `}</strong>
                    <ul>
                      {studyData.primary_outcomes &&
                      studyData.primary_outcomes.length > 0 &&
                      studyData.primary_outcomes[0].outcome_measure !== null ? (
                        studyData.primary_outcomes
                          .filter(
                            (val, index, arr) => arr.indexOf(val) === index
                          )
                          .map((item, index) => (
                            <li key={index}>
                              <span>{item.outcome_measure}</span>
                              {item.outcome_time_frame ? (
                                <Fragment>
                                  <br />
                                  <span>
                                    {`Timeframe: ${item.outcome_time_frame}`}
                                  </span>
                                </Fragment>
                              ) : (
                                ''
                              )}
                            </li>
                          ))
                      ) : (
                        <li>{'Not Available'}</li>
                      )}
                    </ul>
                  </Col>
                  <Col xs={12} className={'outcomes-section left-pad'}>
                    <strong>{`${data.trialDetailsJson.designSection.secondary_outcomes}: `}</strong>
                    <ul>
                      {studyData.secondary_outcomes &&
                      studyData.secondary_outcomes.length > 0 &&
                      studyData.secondary_outcomes[0].outcome_measure !==
                        null ? (
                        studyData.secondary_outcomes
                          .filter(
                            (val, index, arr) => arr.indexOf(val) === index
                          )
                          .map((item, index) => (
                            <li key={index}>
                              <span>{item.outcome_measure}</span>
                              {item.outcome_time_frame ? (
                                <Fragment>
                                  <br />
                                  <span>
                                    {`Timeframe: ${item.outcome_time_frame}`}
                                  </span>
                                </Fragment>
                              ) : (
                                ''
                              )}
                            </li>
                          ))
                      ) : (
                        <li>{'Not Available'}</li>
                      )}
                    </ul>
                  </Col>
                  <Col xs={12} className={'arms-section left-pad'}>
                    <strong>{`${data.trialDetailsJson.designSection.arms}: `}</strong>
                    <ul>
                      <li>
                        {studyData.arm_groups && studyData.arm_groups.length > 0
                          ? studyData.arm_groups.length
                          : 'N/A'}
                      </li>
                    </ul>
                    <strong>{`${data.trialDetailsJson.designSection.interventions}: `}</strong>
                    <ul>
                      {studyData.interventions &&
                      studyData.interventions.length > 0 &&
                      studyData.interventions[0].intervention_name !== null ? (
                        studyData.interventions
                          .filter(
                            (val, index, arr) => arr.indexOf(val) === index
                          )
                          .map((item, index) => (
                            <li
                              key={index}
                            >{`${item.intervention_type}: ${item.intervention_name}`}</li>
                          ))
                      ) : (
                        <li>{'Not Available'}</li>
                      )}
                    </ul>
                  </Col>
                  <Col xs={12} className={'keyword-section left-pad'}>
                    <strong>{`${data.trialDetailsJson.designSection.keyword}: `}</strong>
                    <ul>
                      <li>
                        {studyData.keywords &&
                        studyData.keywords.length > 0 &&
                        studyData.keywords[0] !== null
                          ? studyData.keywords
                              .filter(
                                (val, index, arr) => arr.indexOf(val) === index
                              )
                              .join(', ')
                          : 'Not Available'}
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} className={'criteria-section'}>
                    <strong>{`${data.trialDetailsJson.designSection.criteriaText}: `}</strong>
                    <ModalCriteria
                      buttonText={
                        data.trialDetailsJson.designSection.criteria_button
                      }
                      onClickHandler={() => {
                        trackAnalytics('InclusionExclusionClick', {
                          TrialId: studyData.org_study_id,
                          PositionInList: -1,
                          TrialTitle: studyData.brief_title,
                          TrialStatus: studyData.display_status_internal,
                          Conditions: studyData.conditions.map(
                            cond => cond.InternalValue
                          ),
                        });
                      }}
                    >
                      <h2>
                        {data.trialDetailsJson.designSection.inclusionTitle}
                      </h2>
                      {studyData.inclusion ? (
                        <ul>
                          {studyData.inclusion
                            .split('- ')
                            .filter(val => {
                              return val != '';
                            })
                            .map((val, index) => {
                              return <li key={index}>{val}</li>;
                            })}
                        </ul>
                      ) : (
                        <p>{'Sorry there is no Inclusion Criteria'}</p>
                      )}

                      <h2>
                        {data.trialDetailsJson.designSection.exclusionTitle}
                      </h2>
                      {studyData.exclusion ? (
                        <ul>
                          {studyData.exclusion
                            .split('- ')
                            .filter(val => {
                              return val != '';
                            })
                            .map((val, index) => {
                              return <li key={index}>{val}</li>;
                            })}
                        </ul>
                      ) : (
                        <p>{'Sorry there is no Exclusion Criteria'}</p>
                      )}
                    </ModalCriteria>
                    <div className="criteria-print">
                      <h5>
                        {data.trialDetailsJson.designSection.inclusionTitle}
                      </h5>
                      {studyData.inclusion ? (
                        <ul>
                          {studyData.inclusion
                            .split('- ')
                            .filter(val => {
                              return val != '';
                            })
                            .map((val, index) => {
                              return <li key={index}>{val}</li>;
                            })}
                        </ul>
                      ) : (
                        <p>{'Sorry there is no Inclusion Criteria'}</p>
                      )}

                      <h5>
                        {data.trialDetailsJson.designSection.exclusionTitle}
                      </h5>
                      {studyData.exclusion ? (
                        <ul>
                          {studyData.exclusion
                            .split('- ')
                            .filter(val => {
                              return val != '';
                            })
                            .map((val, index) => {
                              return <li key={index}>{val}</li>;
                            })}
                        </ul>
                      ) : (
                        <p>{'Sorry there is no Exclusion Criteria'}</p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} className={'enrollment-section'}>
                    <p>
                      <strong>{`${data.trialDetailsJson.designSection.enrollment}: `}</strong>
                      {studyData.enrollment
                        ? studyData.enrollment
                        : 'Not Available'}
                    </p>
                  </Col>
                  {studyData.citations &&
                  studyData.citations.length > 0 &&
                  studyData.citations[0] !== null ? (
                    <Col xs={12} className={'publication-section left-pad'}>
                      <strong>{`${data.trialDetailsJson.designSection.clinicalPublications}: `}</strong>
                      <ul>
                        {studyData.citations.map((citation, index) => (
                          <li key={index}>{citation.citation}</li>
                        ))}
                      </ul>
                    </Col>
                  ) : null}
                  <button
                    className="back-to-search-bottom"
                    onClick={event => {
                      event.preventDefault();
                      window.history.go(-1);
                    }}
                  >
                    {data.trialDetailsJson.returnSearch}
                  </button>
                </Row>
              </DetailsPageContainer>
            )}
          </Fonts>
        )}
      </Container>
    </Layout>
  );
};

const DetailsQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        studyNotFoundJson {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        trialDetailsJson {
          title
          returnSearch
          description
          identifier
          clinicalID
          EudraCtID
          euCtID
          resultsAvailable
          docAvailable
          studyOverview
          overviewSection {
            conditionsDisplay
            product
            collaborators
            dates
            studyType
            phase
            gender
            age
            acceptVolunteers
          }
          studyDocuments
          noDocuments
          studyDocumentsAdditionalText
          studyDocumentsAdditionalEmail
          isReleased {
            firstPart
            secondPart
            linkText
          }
          mapLocations
          centralContact
          centralPhone
          centralEmail
          mapSection {
            site
            condition
            id
            city
            state
            zip
            country
          }
          studyDesign
          designSection {
            purpose
            allocation
            study_design
            study_classification
            masking
            masked_subject
            masked_caregiver
            masked_investigator
            masked_assessor
            primary_outcomes
            secondary_outcomes
            arms
            interventions
            keyword
            inclusionTitle
            exclusionTitle
            criteriaText
            criteria_button
            enrollment
            clinicalPublications
          }
          popoversText {
            studyId {
              childMarkdownRemark {
                html
              }
            }
            ctGov {
              childMarkdownRemark {
                html
              }
            }
            eudraCT {
              childMarkdownRemark {
                html
              }
            }
            euCt {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `}
    render={data => <DetailsPage data={data} {...props} />}
  />
);
DetailsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
};

export default DetailsQuery;
